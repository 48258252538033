import React from 'react';
import { ConfusedEmoji } from 'radiance-ui/lib/icons';

import { SEO } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';

import { Wrapper, Title, BodyText } from './Error.style';

export const NotFound = () => (
  <React.Fragment>
    <SEO title="Curology - Not Found" />
    <Nav />
    <Wrapper>
      <ConfusedEmoji width={55} height={55} />
      <Title>Sorry! We mixed things up</Title>
      <BodyText>This page doesn’t exist</BodyText>
    </Wrapper>
  </React.Fragment>
);
